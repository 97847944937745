import React, { useRef, useState } from 'react';
import { FaEye } from "react-icons/fa";

const PreviewButton = ({ editorRef }) => {

  const openPreview = () => {
    const report = editorRef.current.getInstance().getMarkdown();

    const form = document.createElement("form");
    form.method = "POST";
    form.action = "/experiments/preview";
    form.target = "_blank";

    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "report";
    input.value = report;

    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };

  return (
    <div id="preview-button-container">
      <button type="button" onClick={openPreview} className="tw-bg-transparent tw-border-0">
        <FaEye className="tw-text-goa tw-h-[25px] tw-w-[25px]" />
      </button>
    </div>
  );
};

export default PreviewButton;
