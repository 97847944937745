
import React from 'react';

const StatsGroup = ({ children }) => {
  return (
    <div className="tw-p-10 tw-bg-gray-100 tw-mb-10">
      <dl className="tw-mt-5 tw-grid tw-grid-cols-1 tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow md:tw-grid-cols-3 md:tw-divide-x md:tw-divide-y-0">
        {children}
      </dl>
    </div>
  );
}

export default StatsGroup;
