
import React, { useRef, useState } from 'react';
import Modal from '../Modal';

import { TiWarning } from "react-icons/ti";

const InsertAlertButton = ({ editorRef }) => {
  const modalRef = useRef();

  const openModal = () => {
    modalRef.current.open();
  };

  const closeModal = () => {
    modalRef.current.close();
  };

  const [formData, setFormData] = useState({
    type: '',
    title: '',
    text: '',
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const insertAlert = () => {
    const editorInstance = editorRef.current.getInstance();
    let example = `<Alert type="${formData.type}" title="${formData.title}" text="${formData.text}" />`
    if (editorInstance.isMarkdownMode()) {
      example = `\\${example}`
    }
    editorInstance.insertText(`\n${example}\n`);
    closeModal();
  };

  return (
    <div id="quote-button-container">
      <button type="button" onClick={openModal} className="tw-bg-transparent tw-border-0">
        <TiWarning className="tw-text-goa tw-h-[25px] tw-w-[25px]" />
      </button>
      <Modal ref={modalRef} buttonText="Insert Alert" callback={insertAlert}>
        <h2 className="tw-text-4xl tw-font-semibold tw-leading-7 tw-text-gray-900">Insert Alert</h2>

        <div className="tw-border-b tw-border-gray-900/10 tw-pb-12">
          <div className="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-4">

            <div className="sm:tw-col-span-4">
              <label htmlFor="text" className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3">Type</label>
              <div className="tw-mt-2">
                <select id="type" name="type" className="tw-mt-2 tw-block tw-w-full tw-rounded-md tw-border tw-py-1.5 tw-pl-3 tw-pr-10 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-indigo-600 sm:tw-text-3xl sm:tw-leading-6"
                  onChange={handleFormChange}
                  value={formData.type}
                >
                  <option selected>Select Type</option>
                  <option value="info">Info</option>
                  <option value="warning">Warning</option>
                  <option value="error">Error</option>
                  <option value="success">Success</option>
                </select>
              </div>
            </div>

            <div className="sm:tw-col-span-4">
              <label htmlFor="text" className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3">Title</label>
              <div className="tw-mt-2">
                <input id="title" name="title" type="text" className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md tw-border-1 tw-border-gray-300 tw-py-1.5 tw-text-gray-900 placeholder:tw-text-gray-300 tw-border-solid"
                  value={formData.title}
                  onChange={handleFormChange}
                  placeholder="Enter alert title"
                />
              </div>
            </div>

            <div className="sm:tw-col-span-4">
              <label htmlFor="text" className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3">Text</label>
              <div className="tw-mt-2">
                <input id="text" name="text" type="text" className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md tw-border-1 tw-border-gray-300 tw-py-1.5 tw-text-gray-900 placeholder:tw-text-gray-300 tw-border-solid"
                  value={formData.text}
                  onChange={handleFormChange}
                  placeholder="Enter alert text"
                />
              </div>
            </div>


          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InsertAlertButton;
