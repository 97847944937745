import React, { useRef, useState } from 'react';
import Modal from '../Modal';

import { PiSealPercent } from "react-icons/pi";

const InsertStatsButton = ({ editorRef }) => {
  const modalRef = useRef();

  const openModal = () => {
    // let editorInstance = editorRef.current.getInstance();
    // let rangeInfo = editorInstance.getRangeInfoOfNode();
    // console.log(rangeInfo);
    // let selected = editorInstance.getSelectedText(rangeInfo.range[0], rangeInfo.range[1]);
    // console.log(selected);
    // editorInstance.replaceSelection('This has been replaced!', rangeInfo.range[0], rangeInfo.range[1]);
    modalRef.current.open();
  };

  const closeModal = () => {
    modalRef.current.close();
  };

  const [formData, setFormData] = useState({
    title: '',
    value: '',
    fromValue: '',
    increase: '',
    decrease: '',
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const insertStats = () => {
    const editorInstance = editorRef.current.getInstance();
    let example = `<Stats title="${formData.title}" value="${formData.value}" fromValue="${formData.fromValue}" increase="${formData.increase}" decrease="${formData.decrease}"/>`

    if (editorInstance.isMarkdownMode()) {
      example = '\\${example}'
    }
    editorInstance.insertText(`\n${example}\n`);
    closeModal();
  };

  return (
    <div id="stats-button-container">
      <button type="button" onClick={openModal} className="tw-bg-transparent tw-border-0">
        <PiSealPercent className="tw-text-goa tw-h-[25px] tw-w-[25px]" />
      </button>
      <Modal ref={modalRef} buttonText="Insert Stats" callback={insertStats}>
        <h2 className="tw-text-3xl tw-font-semibold tw-leading-7 tw-text-gray-900">Insert Statistic</h2>


        <div className="tw-border-b tw-border-gray-900/10 tw-pb-12">
          <div className="tw-mt-10 tw-grid tw-grid-cols-1 tw-gap-x-6 tw-gap-y-8 sm:tw-grid-cols-4">

            <div className="sm:tw-col-span-4">
              <label htmlFor="title"
                className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3"
              >Title</label>
              <div className="tw-mt-2">
                <input id="title" name="title" type="text"
                  className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-goa placeholder:tw-text-gray-300 tw-border-1 tw-border-gray-300 tw-border-solid"
                  value={formData.title}
                  onChange={handleFormChange}
                  placeholder="Total Subscribers"
                />
              </div>
            </div>

            <div className="sm:tw-col-span-4">
              <label htmlFor="value"
                className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3"
              >Value</label>
              <div className="tw-mt-2">
                <input id="value" name="value" type="text"
                  className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md tw-border-1 tw-border-gray-300 tw-border-solid tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-goa placeholder:tw-text-gray-300"
                  value={formData.value}
                  onChange={handleFormChange}
                  placeholder="90945"
                />
              </div>
            </div>

            <div className="sm:tw-col-span-4">
              <label htmlFor="fromValue"
                className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3"
              >From Value</label>
              <div className="tw-mt-2">
                <input id="fromValue" name="fromValue" type="text"
                  className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md tw-border-1 tw-border-gray-300 tw-border-solid tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-goa placeholder:tw-text-gray-300"
                  value={formData.fromValue}
                  onChange={handleFormChange}
                  placeholder="70945"
                />
              </div>
            </div>

            <div className="sm:tw-col-span-4">
              <label htmlFor="increase"
                className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3"
              >Increase</label>
              <div className="tw-mt-2">
                <input id="increase" name="increase" type="text"
                  className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md tw-border-1 tw-border-gray-300 tw-border-solid tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-goa placeholder:tw-text-gray-300"
                  value={formData.increase}
                  onChange={handleFormChange}
                  placeholder="2%"
                />
              </div>
            </div>

            <div className="sm:tw-col-span-4">
              <label htmlFor="decrease"
                className="tw-block tw-text-3xl tw-font-medium tw-leading-6 tw-text-gray-900 tw-pb-3"
              >Decrease</label>
              <div className="tw-mt-2">
                <input id="decrease" name="decrease" type="text"
                  className="tw-p-2 tw-block tw-text-3xl tw-w-full tw-rounded-md tw-border-1 tw-border-gray-300 tw-border-solid tw-py-1.5 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-goa placeholder:tw-text-gray-300"
                  value={formData.decrease}
                  onChange={handleFormChange}
                  placeholder="0.3%"
                />
              </div>
            </div >
          </div >
        </div >
      </Modal >
    </div >
  );
};

export default InsertStatsButton;
