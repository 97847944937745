import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function BarChart({ data, title, xAxis, yAxis, legend }) {
  const options = {
    theme: "light2",
    animationEnabled: true,
    creditText: "",
    title: {
      text: title
    },
    axisY: {
      title: yAxis
    },
    axisX: {
      title: xAxis
    },
    data: [{
      type: "column",
      showInLegend: true,
      legendMarkerColor: "#1AB28F",
      legendText: legend,
      dataPoints: data
    }]
  }
  return (
    <>
      <CanvasJSChart options={options} />
    </>
  )
}
