
import React from 'react';

const Stats = ({ title, value, fromValue, increase, decrease }) => {
  return (
    <div className="tw-px-4 tw-py-5 sm:tw-p-6">
      <dt className="tw-text-3xl tw-font-normal tw-text-gray-900">{title}</dt>
      <dd className="tw-mt-1 tw-flex tw-items-baseline tw-justify-between md:tw-block lg:tw-flex">
        <div className="tw-flex tw-items-baseline tw-text-4xl tw-font-semibold tw-text-indigo-600">
          {value}
          <span className="tw-ml-2 tw-text-2xl tw-font-medium tw-text-gray-500">from {fromValue}</span>
        </div>

        {increase &&
          <div className="tw-inline-flex tw-ml-4 tw-items-baseline tw-rounded-full tw-px-2.5 tw-py-0.5 tw-text-xl tw-font-medium tw-bg-green-100 tw-text-green-800 md:tw-mt-2 lg:tw-mt-0">
            <svg className="tw--ml-1 tw-mr-0.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-self-center tw-text-green-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clipRule="evenodd" />
            </svg>
            <span className="tw-sr-only"> Increased by </span>
            {increase}
          </div>
        }
        {decrease &&
          <div className="tw-inline-flex tw-ml-4 tw-items-baseline tw-rounded-full tw-px-2.5 tw-py-0.5 tw-text-xl tw-font-medium tw-bg-red-100 tw-text-red-800 md:tw-mt-2 lg:tw-mt-0">
            <svg className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clipRule="evenodd" />
            </svg>
            <span className="tw-sr-only"> Decreased by </span>
            {decrease}
          </div>
        }
      </dd>
    </div>
  );
}

export default Stats;
