
import React from 'react';
import { TiWarning } from "react-icons/ti";
import { MdError } from "react-icons/md";
import { FaInfoCircle } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";


export default function Alert({ type, title, text }) {
  let colors = {};
  let Icon = undefined;

  switch (type) {
    case 'error':
      colors = {
        bg: 'tw-bg-red-50',
        icon: 'tw-text-red-400',
        title: 'tw-text-red-800',
        text: 'tw-text-red-700',
      }
      Icon = MdError;
      break;
    case 'info':
      colors = {
        bg: 'tw-bg-blue-50',
        icon: 'tw-text-blue-400',
        title: 'tw-text-blue-800',
        text: 'tw-text-blue-700',
      }
      Icon = FaInfoCircle;
      break;
    case 'success':
      colors = {
        bg: 'tw-bg-green-50',
        icon: 'tw-text-green-400',
        title: 'tw-text-green-800',
        text: 'tw-text-green-700',
      }
      Icon = FaCheckCircle;
      break;
    case 'warning':
      colors = {
        bg: 'tw-bg-yellow-50',
        icon: 'tw-text-yellow-400',
        title: 'tw-text-yellow-800',
        text: 'tw-text-yellow-700',
      }
      Icon = TiWarning;
      break;
  }


  return (
    <div className={`tw-rounded-lg tw-shadow tw-my-2 ${colors.bg} tw-p-4`}>
      <div className="tw-flex">
        <div className="tw-flex-shrink-0">
          <Icon className={`tw-h-9 tw-mt-2 tw-ml-1 tw-w-9 ${colors.icon}`} aria-hidden="true" />
        </div>
        <div className="tw-ml-4">
          <p className={`tw-text-3xl tw-font-bold ${colors.title} tw-inline-block tw-mt-2 tw-mb-0`}>{title}</p>
          <div className={`tw-text-2xl ${colors.text}`}>
            <p className="tw-m-0">{text}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
