import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';

const Modal = forwardRef(({ buttonText, callback, children }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false)
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className="tw-relative tw-z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity"></div>

          <div className="tw-fixed tw-inset-0 tw-w-screen tw-overflow-y-auto">
            <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">

              <div className="tw-fixed tw-inset-0 tw-z-30 tw-w-screen tw-overflow-y-auto">
                <div className="tw-fixed tw-inset-0 tw-z-40 tw-flex tw-items-center tw-justify-center tw-overflow-y-auto">
                  <div
                    className="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-6xl sm:tw-p-6"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button
                      type="button"
                      className="tw-absolute tw-top-4 tw-right-4 tw-text-gray-500 hover:tw-text-gray-700 tw-text-5xl tw-bg-transparent tw-border-0"
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close</span>
                      &times;
                    </button>
                    {children}
                    <div className="tw-mt-5 sm:tw-mt-6">
                      <button type="button" className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-goa tw-px-3 tw-py-2 tw-text-3xl tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-goa/70 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-goa tw-border-0" onClick={callback}>{buttonText}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
});

export default Modal;
